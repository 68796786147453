import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Gallery from "../components/gallery"
import { TEMP_IMAGES } from "../model/constats"
import { graphql, useStaticQuery } from "gatsby"
import { imageSharpQueryToImgObjects } from "../model/helpers"

const Galerie = props => {
  const data = useStaticQuery(graphql`
      query GalleryQuery {
          allFile(filter: {extension: {in: ["jpg", "jpeg"]}, relativeDirectory: {eq: "gallery"}}) {
              edges {
                  node {
                      childImageSharp {
                          fluid(quality: 80) {
                              src
                          },
                          fixed(width: 1000, quality: 100, jpegQuality: 100) {
                              src
                          }
                      }
                  }
              }
          }
      }
  `)

  const images = imageSharpQueryToImgObjects(data.allFile.edges);

  return (
    <Layout>
      <SEO title="Galerie"/>
      <div className="container mx-auto p-8 mb-3">
        <h1 className="text-5xl text-gray-900">
          Galerie
        </h1>

        <div className="mt-5">
          <Gallery images={ [...images, ]}/>
        </div>
      </div>
    </Layout>
  )
}

export default Galerie
